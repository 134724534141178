<template>
  <div>
    <div class="banner_about">
      <div class="banner_con">
        <h1 class="wow fadeInUp" data-wow-delay="0.3s" style="
          visibility: visible;
          animation-delay: 0.3s;
          animation-name: fadeInUp;
        ">
          关于我们
        </h1>
        <p v-if='!isMob' class="wow fadeInUp" data-wow-delay="0.8s" style="
          visibility: visible;
          animation-delay: 0.8s;
          animation-name: fadeInUp;
        ">
          爱化身科技是一家专注于融合虚拟现实与人工智能的技术提供商，<br>
          让AI与人共创更具创新力和生产力的未来
        </p>
        <p v-if='isMob' class="wow fadeInUp" data-wow-delay="0.8s" style="
          visibility: visible;
          animation-delay: 0.8s;
          animation-name: fadeInUp;
        ">
          爱化身科技是一家专注于融合虚拟现实与人工智能的技术提供商，让AI与人共创更具创新力和生产力的未来
        </p>
      </div>
      <!-- <div class="banner_about_dec wow fadeInUp" data-wow-delay="1.1s" style="visibility: visible; animation-delay: 1.1s; animation-name: fadeInUp;">数字服务实施客户</div> -->
      <!-- <div class="banner_about_com">
        <div class="logo1 wow fadeInUp" data-wow-delay="1.3s" style="visibility: visible; animation-delay: 1.3s; animation-name: fadeInUp;"><img src="@/assets/img/ns_banner_logo1.png" alt="" srcset="">
        </div>
        <div class="logo2 wow fadeInUp" data-wow-delay="1.3s" style="visibility: visible; animation-delay: 1.3s; animation-name: fadeInUp;"><img src="@/assets/img/ns_banner_logo2.png" alt="" srcset="">
        </div>
      </div> -->
    </div>
    <div class="box box5">
      <div class="box_con wcom">
        <div class="img_List">
          <div>
            <img src="@/assets/img/company/中国移动.png" alt="" srcset="" />
            <img src="@/assets/img/company/百信银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/中国建设银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/中国邮政储蓄银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/上海银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/SIEMENS.png" alt="" srcset="" />
            <img src="@/assets/img/company/imglt.png" alt="" srcset="" />
            <img src="@/assets/img/company/兴业银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/中金电信.png" alt="" srcset="" />
            <img src="@/assets/img/company/建投数据.png" alt="" srcset="" />
            <img src="@/assets/img/company/bilibili.png" alt="" srcset="" />
            <img src="@/assets/img/company/吉利汽车.png" alt="" srcset="" />
            <img src="@/assets/img/company/德生科技.png" alt="" srcset="" />
            <img src="@/assets/img/company/人民网.png" alt="" srcset="" />
            <img src="@/assets/img/company/中国推介.png" alt="" srcset="" />
            <img src="@/assets/img/company/imglxzn.png" alt="" srcset="" />
            <img src="@/assets/img/company/imgmg.png" alt="" srcset="" />
            <img src="@/assets/img/company/263网络通信.png" alt="" srcset="" />
            <img src="@/assets/img/company/宇信科技.png" alt="" srcset="" />
            <img src="@/assets/img/company/青藤文化.png" alt="" srcset="" />
            <img src="@/assets/img/company/少林寺.png" alt="" srcset="" />
            <img src="@/assets/img/company/cetv.png" alt="" srcset="" />
            <img src="@/assets/img/company/imgzp.png" alt="" srcset="" />
            <img src="@/assets/img/company/一汽大众.png" alt="" srcset="" />
            <img src="@/assets/img/company/乐元素.png" alt="" srcset="" />
            <img src="@/assets/img/company/车托帮.png" alt="" srcset="" />
            <img src="@/assets/img/company/上汽大通.png" alt="" srcset="" />
            <img src="@/assets/img/company/微软.png" alt="" srcset="" />
            <img src="@/assets/img/company/百度.png" alt="" srcset="" />
            <img src="@/assets/img/company/阿里巴巴.png" alt="" srcset="" />
            <img src="@/assets/img/company/腾讯.png" alt="" srcset="" />
            <img src="@/assets/img/company/京东.png" alt="" srcset="" />
            <img src="@/assets/img/company/微博.png" alt="" srcset="" />
          </div>
          <div>
            <img src="@/assets/img/company/中国移动.png" alt="" srcset="" />
            <img src="@/assets/img/company/百信银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/中国建设银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/中国邮政储蓄银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/上海银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/SIEMENS.png" alt="" srcset="" />
            <img src="@/assets/img/company/imglt.png" alt="" srcset="" />
            <img src="@/assets/img/company/兴业银行.png" alt="" srcset="" />
            <img src="@/assets/img/company/中金电信.png" alt="" srcset="" />
            <img src="@/assets/img/company/建投数据.png" alt="" srcset="" />
            <img src="@/assets/img/company/bilibili.png" alt="" srcset="" />
            <img src="@/assets/img/company/吉利汽车.png" alt="" srcset="" />
            <img src="@/assets/img/company/德生科技.png" alt="" srcset="" />
            <img src="@/assets/img/company/人民网.png" alt="" srcset="" />
            <img src="@/assets/img/company/中国推介.png" alt="" srcset="" />
            <img src="@/assets/img/company/imglxzn.png" alt="" srcset="" />
            <img src="@/assets/img/company/imgmg.png" alt="" srcset="" />
            <img src="@/assets/img/company/263网络通信.png" alt="" srcset="" />
            <img src="@/assets/img/company/宇信科技.png" alt="" srcset="" />
            <img src="@/assets/img/company/青藤文化.png" alt="" srcset="" />
            <img src="@/assets/img/company/少林寺.png" alt="" srcset="" />
            <img src="@/assets/img/company/cetv.png" alt="" srcset="" />
            <img src="@/assets/img/company/imgzp.png" alt="" srcset="" />
            <img src="@/assets/img/company/一汽大众.png" alt="" srcset="" />
            <img src="@/assets/img/company/乐元素.png" alt="" srcset="" />
            <img src="@/assets/img/company/车托帮.png" alt="" srcset="" />
            <img src="@/assets/img/company/上汽大通.png" alt="" srcset="" />
            <img src="@/assets/img/company/微软.png" alt="" srcset="" />
            <img src="@/assets/img/company/百度.png" alt="" srcset="" />
            <img src="@/assets/img/company/阿里巴巴.png" alt="" srcset="" />
            <img src="@/assets/img/company/腾讯.png" alt="" srcset="" />
            <img src="@/assets/img/company/京东.png" alt="" srcset="" />
            <img src="@/assets/img/company/微博.png" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <div class="box3" v-if="!isMob">
      <div class="box_con">
        <div class="box_con_btn btn_left"><i></i></div>
        <div class="box_con_btn btn_right"><i></i></div>
        <div id="scrollArea" class="swiper">
          <div class="swiper_wrap">
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_1.png" alt="" srcset="">
              <div class="index">01</div>
              <div class="name">
                <h1>Lieene</h1>
                <span>CTO&联合创始人</span>
              </div>
              <ul>
                <li>北京大学VR/AR技术讲师，硕士生导师</li>
                <li>微软MRPP（MR技术合作伙伴）</li>
                <li>VR/AR行业连续创业12年/创业导师</li>
                <li>
                  英国学院奖（BAVGA）提名主导"Generative Motion Matching”自然语言驱动算法的研发
                </li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_2.png" alt="" srcset="">
              <div class="index">02</div>
              <div class="name">
                <h1>Wayne</h1>
                <span>首席架构师</span>
              </div>
              <ul>
                <li>ICT大厂14年工作经验，资深ICT专家，多年人工智能算法,产品和项目工程化经验。</li>
                <li>曾在中国移动,华为,阿里巴巴,腾讯担任高级管理，领导并参与了多项重大ICT项目，推动了公司的技术创新和业务增长。</li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_3.png" alt="" srcset="">
              <div class="index">03</div>
              <div class="name">
                <h1>Shawn</h1>
                <span>图形引擎专家</span>
              </div>
              <ul>
                <li>美国斯坦福大学硕士</li>
                <li>曾任北大研究生教授，计算机视觉、图形学方向</li>
                <li>谷游科技、中华网游戏、侠客行网络创始人兼CTO</li>
                <li>师从图灵奖得主：Patrick Hanrahan、Jeff Ullman</li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_4.png" alt="" srcset="">
              <div class="index">04</div>
              <div class="name">
                <h1 class="name_h1">Ghostblade</h1>
                <span>首席科学家</span>
              </div>
              <ul>
                <li>北京大学CS/SE硕士，浙江大学CAD&CG博士</li>
                <li>计算机辅助设计与图形学国家重点实验室研完员</li>
                <li>VR与游戏行业连续创业者</li>
                <li>负责公司图形图像算法相关研发</li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_5.png" alt="" srcset="">
              <div class="index">05</div>
              <div class="name">
                <h1>Abner</h1>
                <span>图形算法科学家</span>
              </div>
              <ul>
                <li>北京大学CS/SE硕士，图形研发工程师</li>
                <li>
                  微软亚洲研究研院，创新工程组 Diaital
                  Assistant&个人Al助理项目研发
                </li>
                <li>
                  完美世界沙盒项目组，TA（技术美术）负责美术工作流搭建、美术工具研发、环境特效研究
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="btn btn_left"><i class="iconfont iconfont icon-xiangzuo1"></i></div>
            <div class="btn btn_right"><i class="iconfont iconfont icon-xiangyou1"></i></div> -->
      </div>
    </div>
    <div class="box3 box4" v-if="isMob">
      <div class="box_con">
        <div class="swiper">
          <div class="swiper_wrap">
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_1.png" alt="" srcset="">
              <div class="index">01</div>
              <div class="name">
                <h1>Lieene</h1>
                <span>CTO&联合创始人</span>
              </div>
              <ul>
                <li>北京大学VR/AR技术讲师，硕士生导师</li>
                <li>微软MRPP（MR技术合作伙伴）</li>
                <li>VR/AR行业连续创业12年/创业导师</li>
                <li>
                  英国学院奖（BAVGA）提名主导"Generative Motion Matching”自然语言驱动算法的研发
                </li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_2.png" alt="" srcset="">
              <div class="index">02</div>
              <div class="name">
                <h1>Wayne</h1>
                <span>首席架构师</span>
              </div>
              <ul>
                <li>ICT大厂14年工作经验，资深ICT专家，多年人工智能算法,产品和项目工程化经验。</li>
                <li>曾在中国移动,华为,阿里巴巴,腾讯担任高级管理，领导并参与了多项重大ICT项目，推动了公司的技术创新和业务增长。</li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_3.png" alt="" srcset="">
              <div class="index">03</div>
              <div class="name">
                <h1>Shawn</h1>
                <span>图形引擎专家</span>
              </div>
              <ul>
                <li>美国斯坦福大学硕士</li>
                <li>曾任北大研究生教授，计算机视觉、图形学方向</li>
                <li>谷游科技、中华网游戏、侠客行网络创始人兼CTO</li>
                <li>师从图灵奖得主：Patrick Hanrahan、Jeff Ullman</li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_4.png" alt="" srcset="">
              <div class="index">04</div>
              <div class="name">
                <h1 class="name_h1">Ghostblade</h1>
                <span>首席科学家</span>
              </div>
              <ul>
                <li>北京大学CS/SE硕士，浙江大学CAD&CG博士</li>
                <li>计算机辅助设计与图形学国家重点实验室研完员</li>
                <li>VR与游戏行业连续创业者</li>
                <li>负责公司图形图像算法相关研发</li>
              </ul>
            </div>
            <div class="item">
              <img class="h_i" src="@/assets/img/d2_5.png" alt="" srcset="">
              <div class="index">05</div>
              <div class="name">
                <h1>Abner</h1>
                <span>图形算法科学家</span>
              </div>
              <ul>
                <li>北京大学CS/SE硕士，图形研发工程师</li>
                <li>
                  微软亚洲研究研院，创新工程组 Diaital
                  Assistant&个人Al助理项目研发
                </li>
                <li>
                  完美世界沙盒项目组，TA（技术美术）负责美术工作流搭建、美术工具研发、环境特效研究
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="btn btn_left"><i class="iconfont iconfont icon-xiangzuo1"></i></div>
            <div class="btn btn_right"><i class="iconfont iconfont icon-xiangyou1"></i></div> -->
      </div>
    </div>

  </div>
</template>

<script>
import { WOW } from 'wowjs'
import 'animate.css'
// import swiper from 'swiper'
// import 'swiper/swiper-bundle.min.css'
import myMenu from '@/components/myMenu.vue'
export default {
  components: {
    myMenu,
  },
  data() {
    return {
      point: false,
      pointTimer: null,
      isMob: false
    }
  },
  // computed: {
  //   isMob() {
  //     return this.$store.state.isMob
  //   }
  // },
  watch: {
    '$store.state.isMob'(newVal, oldVal) {
      this.isMob = newVal
      // console.log(newVal, oldVal);
      this.$forceUpdate()
      if (this.statusPage > 0) {
        // console.log('页面刷新');
        this.reload();
        this.isMob = newVal
        // window.location.reload()
      }
    }
  },
  mounted() {

    var wow = new WOW({
      animateClass: 'animated',
      offset: 100,
    })
    wow.init()
    this.isMob = this.$store.state.isMob
    if (!this.isMob) {
      $(function () {

        var that = this
        $('.btn_left').hide()
        $('.btn_right').hide()
        $('.box3 .box_con_btn').mouseenter(function (params) {
          clearTimeout(that.pointTimer)
        })
        $('#scrollArea').mouseleave(function (params) {
          clearTimeout(that.pointTimer)
          that.pointTimer = setTimeout(() => {
            that.point = false
            $('.btn_left').fadeOut()
            $('.btn_right').fadeOut()
            clearTimeout(that.pointTimer)
          }, 1000)
        })
        $('#scrollArea').mousemove(function (params) {
          if (window.innerWidth > 1621) {
            return
          }
          that.point = true
          if (that.point) {
            $('.btn_left').fadeIn()
            $('.btn_right').fadeIn()
            clearTimeout(that.pointTimer)
            that.pointTimer = setTimeout(() => {
              that.point = false
              $('.btn_left').fadeOut()
              $('.btn_right').fadeOut()
              clearTimeout(that.pointTimer)
            }, 1000)
          }
        })


        var swiper2 = document.getElementsByClassName('swiper')[0]
        var touching = false
        // swiper2.ontouchstart = function () {
        //   touching = true
        // }
        // swiper2.ontouchend = function () {
        //   console.log(22222222);
        //   beepos2.x = swiper2.scrollLeft
        //   mouse2.x = swiper2.scrollLeft
        // }
        swiper2.addEventListener('mousewheel', handleScroll);
        function handleScroll(event) {
          //禁止事件默认行为（此处禁止鼠标滚轮行为关联到"屏幕滚动条上下移动"行为）
          if (event.deltaX != 0) {
            // 如果是左右滚动，阻止默认行为
            event.preventDefault();
          } else {
            if (window.innerWidth > 1621) {
              return
            }
            that.point = true
            if (that.point) {
              $('.btn_left').fadeIn()
              $('.btn_right').fadeIn()
              clearTimeout(that.pointTimer)
              that.pointTimer = setTimeout(() => {
                that.point = false
                $('.btn_left').fadeOut()
                $('.btn_right').fadeOut()
                clearTimeout(that.pointTimer)
              }, 1000)
            }
          }
        }
        var beepos2 = {
          x: 0,
        }
        var mouse2 = {
          x: 0,
        }
        setInterval(followMouse2, 50)
        function getMouse(step) {
          mouse2.x = step
        }
        function followMouse2() {
          if (touching) {
            console.log(22222);
            return
          }
          var distX = mouse2.x - beepos2.x
          beepos2.x += distX / 2
          if (swiper2.scrollLeft != beepos2.x) {
            swiper2.scrollLeft = beepos2.x
          }
        }
        var currentX =
          (document.getElementsByClassName('swiper_wrap')[0].offsetWidth -
            swiper2.offsetWidth) /
          2
        // getMouse(swiper2.scrollLeft + currentX)
        // 左右按钮
        $('.box_con .btn_right').click(function () {
          getMouse(swiper2.scrollLeft + 300)
        })
        $('.box_con .btn_left').click(function () {
          getMouse(swiper2.scrollLeft - 300)
        })
      })

    }
    window.addEventListener('resize', () => this.handleResize())


  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 1621) {
        this.point = true
        if (this.point) {
          $('.btn_left').fadeIn()
          $('.btn_right').fadeIn()
          clearTimeout(this.pointTimer)
          this.pointTimer = setTimeout(() => {
            this.point = false
            $('.btn_left').fadeOut()
            $('.btn_right').fadeOut()
            clearTimeout(this.pointTimer)
          }, 1000)
        }
      } else {
        $('.box3 .box_con_btn').fadeOut()
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.box3 .swiper::-webkit-scrollbar {
  display: none;
}
.banner_about {
  width: 100%;
  height: 720rem;
  background: url('@/assets/img/d1_1.png') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  backdrop-filter: blur(26.8px);
  background-color: rgb(0 0 0 / 66%);
}
.banner_con {
  width: 1200rem;
  height: 215rem;
  top: calc(50% - -10rem);
  left: 50%;
  /* display: flex; */
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-image: url(@/assets/img/d1_11.png);
  background-size: 1014rem 215rem;
  background-position: 1rem 0;
  background-position: center -4rem;
  background-repeat: no-repeat;
}
.banner_con h1 {
  color: #fff;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}
.banner_con p {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-top: 10rem;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.banner_about_dec {
  font-weight: 500;
  font-size: 14rem;
  line-height: 16rem;
  text-align: center;
  color: #fff;
  margin: 108rem 0 16rem 0;
}

.banner_about_com {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner_about_com > div {
  width: 125rem;
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 10rem;
  background: #5f21b4;
  border: 1rem solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10rem);
}

.banner_about_com > div img {
  position: relative;
  width: 89rem;
  z-index: 1;
}

.box3 .box_con {
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 670rem;
  position: relative;
  // margin-bottom: 30rem;
}

.box3 .swiper {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 670rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: auto;
  -ms-overflow-style: none;
}

.box3 .swiper_wrap {
  display: flex;
  align-items: center;
  height: 100%;
  width: fit-content;
  margin: 0 auto;
}
.box3 .swiper .item .h_i {
  position: absolute;
  width: 235rem;
  top: -89rem;
}
.box3 .swiper .item {
  width: 285rem;
  height: 490rem;
  background: #f5f5f5;
  backdrop-filter: blur(15px);
  margin: 0 15rem;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  padding: 30rem 26rem;
}
.box3 .swiper .item:first-child {
  margin-left: 60rem;
}
.box3 .swiper .item:last-child {
  margin-right: 60rem;
}
.box3 .swiper .item .index {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 30rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  font-size: 12rem;
  margin-top: 82rem;
}

.box3 .swiper .item .name {
  color: #000;
  display: flex;
  align-items: center;
  margin-top: 25rem;
  margin-bottom: 50rem;
  position: relative;
  /* justify-content: center; */
}

.box3 .swiper .item .name::after {
  position: absolute;
  content: '';
  width: 41rem;
  height: 4rem;
  background: #acacac;
  left: 0;
  bottom: -24rem;
}

.box3 .swiper .item .name h1 {
  font-size: 28rem;
}

.name_h1 {
  font-size: 23rem !important;
}


.box3 .swiper .item .name span {
  margin-top: 4rem;
  font-size: 15rem;
  margin-left: 10rem;
  display: inline-block;
  font-weight: 700;
}

// .box3 .swiper .item::after {
//   content: '';
//   width: 1rem;
//   height: 52px;
//   background-color: #c4c4c4;
//   top: 26rem;
//   left: 30rem;
//   position: absolute;
// }

// .box3 .swiper .item::before {
//   content: '';
//   width: 52px;
//   height: 1rem;
//   background-color: #c4c4c4;
//   left: 17rem;
//   top: 41rem;
//   position: absolute;
// }

.box3 .swiper .item ul {
  // padding-left: 16rem;
  color: #000;
  font-size: 14rem;
}

.box3 .swiper .item li {
  margin-bottom: 16rem;
}

.box3 .btn {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 54rem;
  width: 54rem;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.box3 .btn.btn_left {
  left: 30rem;
}

.box3 .btn.btn_right {
  right: 30rem;
}

/* box5 */
.box5 {
  padding-bottom: 45rem;
  background-color: #fff;
  .box_con {
    overflow: hidden;
  }
}

.box5 h1 {
  padding: 90rem 0 52rem;
  font-weight: 700;
  font-size: 36rem;
  /* line-height: 42rem; */
  text-align: center;

  color: #fff;
}

.box5 .img_List {
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-top: 42rem;
}

.box5 .img_List > div {
  animation-name: scroll;
  animation-duration: 100s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  // animation: scroll 10s linear 0 infinite alternate;
  animation-play-state: running;
  animation-delay: 0s;
  animation-direction: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.box5 .img_List img {
  height: 80rem;
}
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.box3 .btn_left {
  width: 68rem;
  height: 68rem;
  cursor: pointer;
  background-image: url('@/assets/img/icon_left_ed.png');
  background-image: url('@/assets/img/icon_left.png');
  background-size: cover;
  position: absolute;
  top: calc(50% - 70rem);
  left: 50rem;
  z-index: 2;
  &:hover {
    background-image: url('@/assets/img/icon_left_ed.png');
  }
}
.box3 .btn_right {
  width: 68rem;
  height: 68rem;
  cursor: pointer;
  background-image: url('@/assets/img/icon_right_ed.png');
  background-image: url('@/assets/img/icon_right.png');
  background-size: cover;
  position: absolute;
  top: calc(50% - 70rem);
  right: 50rem;
  z-index: 2;
  &:hover {
    background-image: url('@/assets/img/icon_right_ed.png');
  }
}
@media screen and (max-width: 767px) {
  .banner_about {
    height: 100vh !important;
    .banner_con {
      width: 638rem;
    }
    h1 {
      font-size: 70rem;
    }
    p {
      font-size: 30rem;
    }
  }
  .box4 {
    .box_con {
      height: auto;
      padding-bottom: 20rem;
    }
    .swiper {
      position: relative;
    }
    .swiper_wrap {
      display: flex;
      flex-direction: column;
      .item {
        width: 662rem;
        height: auto;
        margin: 220rem 0 50rem !important;
        padding: 60rem 59rem;
        .h_i {
          width: 545rem;
          top: -205rem;
          left: 0;
          right: 0;
          margin: auto;
        }
        .index {
          margin-top: 322rem;
        }
        .name::after {
          width: 96rem;
          height: 10rem;
          bottom: -32rem;
        }
        .name h1 {
          font-size: 62rem;
        }
        .name span {
          font-size: 44rem;
        }
        ul {
          font-size: 30rem;
          line-height: 35rem;
          li {
            margin-bottom: 34rem;
            color: #5e5e5e;
          }
        }
      }
    }
  }
}
</style>

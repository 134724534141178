<template>
  <div>
    <div class="recruit ">
      <div class="banner">
        <div class="banner_con">
          <h1 class="wow fadeInUp" data-wow-delay="0.3s" style="
          visibility: visible;
          animation-delay: 0.3s;
          animation-name: fadeInUp;
        ">欢迎加入我们</h1>
          <p class="wow fadeInUp" data-wow-delay="0.8s" style="
          visibility: visible;
          animation-delay: 0.8s;
          animation-name: fadeInUp;
        ">诚挚欢迎各位贤才加入爱化身，如有意向职位请您将简历投递邮箱至
            <br />HR@aihuashen.com
          </p>
        </div>
      </div>
      <div class="con">
        <div class="list">
          <div class="list_item">
            <div class="item_title">大模型解决方案专家</div>
            <div class="item_con">
              <div class="dec dec_title">岗位职责</div>
              <div class="dec">
                <span>1.</span>
                <span> 提供专业服务，包括为客户设计产品/项目架构和系统解决方案；</span>
              </div>
              <div class="dec">
                <span>2.</span>
                <span> 在项目售前阶段，负责需求分析、方案交流和方案设计，根据客户需求设计个性化解决方案；</span>
              </div>
              <div class="dec">
                <span>3.</span>
                <span> 追踪AIGC等产业和行业技术趋势及最新动态，制定行业策略，形成研究报告，为行业解决方案和产品设计提供指导性建议和长期规划；</span>
              </div>
              <div class="dec">
                <span>4.</span>
                <span> 整理行业业务特点，进行竞争对手分析，提出竞争策略，并给出解决方案。</span>
              </div>
              <div class="dec dec_title">任职要求</div>
              <div class="dec">
                <span>1.</span>
                <span> 硕士及以上学历，具备强烈的责任心、抗压能力和工作热情，具有敏锐的商业意识，能够快速发掘和响应客户需求，具有良好的执行力、观察力和沟通协调能力；</span>
              </div>
              <div class="dec">
                <span>2.</span>
                <span> 拥有三年以上在AIGC或解决方案领域的工作经验，具备项目总体规划经验，能够灵活稳重地处理事务，具有正直、诚实守信和踏实能干的品质；</span>
              </div>
              <div class="dec">
                <span>3.</span>
                <span> 具备优秀的文字表达能力，熟练使用Word、Excel、PPT等办公软件。</span>
              </div>
            </div>
          </div>
          <div class="list_item">
            <div class="item_title">AI算法/深度学习工程师</div>
            <div class="item_con">
              <div class="dec dec_title">岗位职责</div>
              <div class="dec">
                <span>1.</span>
                <span> 设计并实施高效、可扩展的图像和视频生成、编辑及增强算法，利用生成对抗网络（GANs）、变分自编码器（VAEs）、Transformer和扩散模型等先进技术；</span>
              </div>
              <div class="dec">
                <span>2.</span>
                <span> 紧跟人工智能领域的最新研究和技术进展，特别是在图像和视频生成技术方面，如Transformer和扩散模型，并积极将这些前沿技术应用到实际项目中；</span>
              </div>
              <div class="dec">
                <span>3.</span>
                <span> 与产品和工程团队紧密合作，确保将复杂的机器学习模型有效地集成到产品中，满足业务需求，并在性能和质量上达到高标准；
                </span>
              </div>
              <div class="dec">
                <span>4.</span>
                <span> 深入分析和解决模型训练和部署过程中遇到的挑战，包括模型优化、扩展性问题和提高计算资源的利用率；</span>
              </div>
              <div class="dec">
                <span>5.</span>
                <span> 贡献高质量的代码库，编写详尽的技术文档，并通过内部分享提升团队的技术能力和知识水平。</span>
              </div>
              <div class="dec dec_title">任职要求</div>
              <div class="dec">
                <span>1.</span>
                <span> 持有计算机科学、人工智能、应用数学或相关领域的硕士或博士学位；</span>
              </div>

              <div class="dec">
                <span>2.</span>
                <span> 具备至少2年深度学习、计算机视觉或相关领域的工作经验，尤其在图像和视频生成方面；</span>
              </div>
              <div class="dec">
                <span>3.</span>
                <span> 对生成对抗网络（GANs）、变分自编码器（VAEs）、Transformer和扩散模型等生成模型有深入的理解，并具有实际应用和研究经验； </span>
              </div>
              <div class="dec">
                <span>4.</span>
                <span> 精通Python编程，熟练掌握TensorFlow、PyTorch等主流深度学习框架；</span>
              </div>
              <div class="dec">
                <span> 5.</span>
                <span> 具有出色的问题解决能力，能够独立进行技术研究并解决相关问题；</span>
              </div>
              <div class="dec">
                <span>6.</span>
                <span> 拥有良好的团队合作精神和沟通技巧，能够在快节奏环境中与不同团队有效协作。</span>
              </div>
            </div>
          </div>
          <div class="list_item">
            <div class="item_title">AIGC 产品经理</div>
            <div class="item_con">
              <div class="dec dec_title">岗位职责</div>
              <div class="dec">
                <span>1.</span>
                <span> 洞察公司业务领域的行业趋势，结合客户调研、竞品分析、项目需求等，完成AI平台及AI应用的规划与管理；
                </span>
              </div>
              <div class="dec">
                <span>2.</span>
                <span> 负责相关功能的设计，输出原型、产品需求文档（PRD）等设计文档；</span>
              </div>
              <div class="dec">
                <span>3.</span>
                <span> 与产品研发、项目交付团队合作，推动产品新功能的实现、已有功能的完善以及项目内容的交付； </span>
              </div>
              <div class="dec">
                <span>4.</span>
                <span> 支持公司AI及其他业务相关项目的售前交流、方案编写、项目投标、知识产权申请等工作；</span>
              </div>
              <div class="dec">
                <span>5.</span>
                <span> 完成部门领导安排的其他任务。</span>
              </div>
              <div class="dec dec_title">任职要求</div>
              <div class="dec">
                <span>1.</span>
                <span> 全日制本科及以上学历，计算机相关专业背景，3年及以上软件产品设计工作经验；</span>
              </div>
              <div class="dec">
                <span>2.</span>
                <span> 熟悉机器学习、深度学习、大模型应用开发的全生命周期流程；</span>
              </div>
              <div class="dec">
                <span>3.</span>
                <span> 熟悉常用的AI框架、模型、数据集，并对Kubernetes（K8S）、MLOps有一定的了解；</span>
              </div>
              <div class="dec">
                <span>4.</span>
                <span> 熟悉AI平台/中台的功能架构、技术架构，有AI平台产品从0到1的设计落地经验者优先；</span>
              </div>
              <div class="dec">
                <span>5.</span>
                <span> 有大模型微调、Retrieval-Augmented Generation（RAG）、AI Agent类产品相关经验者优先；</span>
              </div>
              <div class="dec">
                <span>6.</span>
                <span> 具备高水平的需求分析、产品设计、沟通访谈、方案编制、文档撰写能力；</span>
              </div>
              <div class="dec">
                <span>7.</span>
                <span> 具备良好的沟通协调和问题解决能力，工作积极主动、认真负责，且具有强烈的学习能力。</span>
              </div>
            </div>
          </div>
          <div class="list_item">
            <div class="item_title">管培生</div>
            <div class="item_con">
              <div class="dec dec_title">岗位职责</div>
              <div class="dec">
                <span>1.</span>
                <span> 以战略性工作为主导：深入观察国家宏观发展和重点产业链动态，并且深度调研国内外前沿元宇宙虚拟人、虚拟场景公司，分析元宇宙行业趋势，形成行业洞察，为公司整体战略核心团队提供决策建议； </span>
              </div>
              <div class="dec">
                <span>2.</span>
                <span> 新媒体运营：精美的PPT并非这个世界的全貌，文档也不是调研的全部。我们鼓励你与公司中台市场团队紧密配合，在新媒体矩阵上持续发声，创造传播亮点； </span>
              </div>
              <div class="dec">
                <span>3.</span>
                <span> 项目统筹：整合内部、生态伙伴、客户侧资源，在导师的带领下统筹团队，完成跨团队的配合与协作，确保产品交付工作的顺利实施；
                </span>
              </div>
              <div class="dec">
                <span>4.</span>
                <span> 大客户提案：在团队领导的带领下，参与面向大客户的前期提案工作，深入了解客户需求，建立直接的客户认知。</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myMenu from '@/components/myMenu.vue';
import { WOW } from 'wowjs'
export default {
  components: {
    myMenu,
  },
  mounted() {
    var wow = new WOW({
      animateClass: 'animated',
      offset: 100,
    })
    wow.init()
  },
}
</script>

<style lang="scss" scoped>
.recruit {
}
.banner {
  background-image: url(@/assets/img/e1.png);
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  height: 720rem;
  background-size: cover;
  backdrop-filter: blur(10rem);
}
.banner_con {
  width: 1200rem;
  height: 211rem;
  top: calc(50% - -10rem);
  left: 50%;
  /* display: flex; */
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  background-image: url(@/assets/img/e1_1.png);
  background-size: 942rem 211rem;
  background-position: 1rem 0;
  background-position: center -4rem;
  background-repeat: no-repeat;
}
.banner_con h1 {
  color: #fff;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}
.banner_con p {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-top: 10rem;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}
.con {
  overflow: hidden;
  background-color: #fff;
  background: rgba(255, 255, 255, 1);
}
.list {
  width: 1000rem;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 40rem;
}
.list_item {
  margin-bottom: 60rem;
}
.item_title {
  color: #000;
  font-size: 22rem;
  font-weight: 700;
  position: relative;
  margin-top: 20rem;
}
.item_title::after {
  content: '';
  width: 6rem;
  height: 20rem;
  position: absolute;
  background-color: #000;
  left: -20rem;
  top: 6rem;
}
.item_con {
  color: #000;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
.item_con .dec {
  display: flex;
  margin-bottom: 8px;
}
.dec_title {
  margin-top: 20rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .banner {
    height: 100vh !important;
    .banner_con {
      width: 638rem;
    }
    h1 {
      font-size: 70rem;
    }
    p {
      font-size: 30rem;
    }
  }
  .con {
    padding: 44rem;
  }
  .list {
    width: auto;
    padding: 0;
  }
  .item_title {
    font-size: 36rem;
    margin-top: 0;
    margin-left: 24rem;
    &::after {
      height: 36rem;
      top: 8rem;
    }
  }
  .item_con {
    padding: 0 24rem;
  }
  .dec_title {
    font-size: 30rem !important;
    margin-top: 30rem;
  }
  .dec {
    font-size: 24rem;
    line-height: 32rem;
  }
}
</style>
<template>
  <div id="app">
    <div class="main">
      <myMenu ref="myMenu">
      </myMenu>
      <router-view v-if="isRouterAlive"></router-view>
      <footerCon></footerCon>
    </div>
  </div>
</template>
<script>
import footerCon from '@/components/footer.vue';
import myMenu from '@/components/myMenu.vue';
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。 
    return {

      reload: this.reload
    }
  },
  components: {
    footerCon,
    myMenu
  },
  data() {

    return {

      isRouterAlive: true                    //控制视图是否显示的变量
    }
  },
  methods: {
    getMob() {
      var status = window.innerWidth >= 767 ? false : true
      this.$store.commit('setIsMob', status)
    },
    reload() {

      this.isRouterAlive = false;            //先关闭，
      this.$nextTick(function () {

        this.isRouterAlive = true;         //再打开
      })
    }
  },
  mounted() {
    var that = this
    this.getMob()
    window.addEventListener('resize', () => this.getMob())
    // 

    document.addEventListener("WeixinJSBridgeReady",function() {
      //监听是微信浏览器
      that.$store.commit('setIsWx', true) 
      //  window.WeixinJSBridge && window.WeixinJSBridge.invoke('getNetworkType', {}, () => {
      // sound1.play()
      var isIos = /iPad|iPhone|iPod/.test(navigator.userAgent)
      // setTimeout(() => {
        if(isIos){
          // this.$nextTick(()=>{
            // $('video')[0].play() 
            that.$store.commit('setIsClick', true) 
          // })
          
            // document.addEventListener('touchstart', function(event) {
            // // 确认事件是在document上触发，而不是某个具体元素上
            // if(!that.$store.state.isMob){
            //   return
            // }
            // if(that.$store.state.isClick){
            //   return
            // } 
            // // that.$store.commit('setIsClick', true) 
            // }, true);  
        }else{
          that.$store.commit('setIsWx', true) 
          document.addEventListener('click', function(event) {
            // 确认事件是在document上触发，而不是某个具体元素上
            if(!that.$store.state.isMob){
              return
            }
            if(that.$store.state.isClick){
              return
            } 
            that.$store.commit('setIsClick', true) 
            }, true);  
        }
        
            // }, 0);
          // }, false)
    }, false);
     
  }
}
</script>
<template>
  <div>
    <div class="technology banner" :style="{ background: bgList[active] }">
      <div v-if="isMob" @click="swiper('left')" class="btn_left"><i></i></div>
      <div v-if="isMob" @click="swiper('right')" class="btn_right"><i></i></div>
      <div class="banner_center">
        <div class="text_box">
          <div class="box_item box1" v-if="active == 0">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              复杂动作交互系统
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              能够处理多种运动模式，驱使角色连贯执行走、跑、跳、蹲、攀爬、举物等动作
            </p>
          </div>
          <div class="box_item box2" v-if="active == 1">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              Image to 3D
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              利用3DGS技术，开发深度神经网络，通过分析和训练点云数据，实现骨骼驱动的3DGS数字人快速生成
            </p>
          </div>
          <div class="box_item box3" v-if="active == 2">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              理解现实世界人和物体的运动规则
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              通过在频域空间的训练，将大规模的复杂动作数据库训练一个独立的深度网络模型，可以连续的生成实时最优动作，并支持音乐/手柄/文本/语音指令/关键动作补帧等不同模态的输入
            </p>
          </div>
          <div class="box_item box4" v-if="active == 3">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              Speech to Lip
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              自研并训练语音生成唇形动作AI模型，支持任意语言的唇形动作生成，首字响应时间小于100毫秒，可以实时驱动标准的角色口唇及面部表情
            </p>
          </div>
          <div class="box_item box5" v-if="active == 4">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              微观细节模型/动作生成
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              完美还原角色身体细节，包括面部、手和脚部等模型和动态细节
            </p>
          </div>
          <div class="box_item box6" v-if="active == 5">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              多骨骼标准
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              提供多种模型选择，适应不同模型的骨骼结构和身材比例差异
            </p>
          </div>
          <div class="box_item box7" v-if="active == 6">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              精准面部捕捉
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              识别并跟踪数百个面部关键点，实现对人物表情的精确数字化，从而实时/离线捕捉演员的面部表情，并迁移至虚拟角色上
            </p>
          </div>
          <div class="box_item box8" v-if="active == 7">
            <h1 class="animate__animated animate__lightSpeedInLeft animate__delay3">
              Asset Generation Service
            </h1>
            <p class="animate__animated animate__lightSpeedInLeft animate__delay6">
              中心化资产内容储存及标准化统一管理分发
            </p>
          </div>
        </div>
        <div v-if="!isMob" class="pro_canvas" style="width: 1920px; height: 720px;"></div>
        <div v-if="isMob" class="pro_canvas" style="width: 750rem; height: 1164rem;"></div>
      </div>
      <div class="index_list">
        <span @click="toSpan(index)" v-for="(item, index) in list" :key="index" :class="{ active: active == index }"></span>
      </div>
    </div>
  </div>
</template>

<script>
import myMenu from '@/components/myMenu.vue'
import { computed, reactive, ref, toRefs } from 'vue'
// import swiper from 'swiper'
// import 'swiper/swiper-bundle.min.css'
import 'wowjs/css/libs/animate.css'
import '@/assets/css/wowExtra.css'
import { WOW } from 'wowjs'
import hoverEffect from 'hover-effect'
import 'animate.css'

export default {
  inject: ['reload'],
  data() {
    return {
      active: 0,
      myAnimation: null,
      list: [
        require('@/assets/img/c1_1.png'),
        require('@/assets/img/c1_2.png'),
        require('@/assets/img/c1_3.png'),
        require('@/assets/img/c1_4.png'),
        require('@/assets/img/c1_5.png'),
        require('@/assets/img/c1_6.png'),
        require('@/assets/img/c1_7.png'),
        require('@/assets/img/c1_8.png'),
      ],
      bgList: [
        '#22bea2 ',
        '#2e70a0',
        '#22bea2',
        ' #aa8f4c',
        '#2e70a0',
        '#22bea2',
        '#aa8f4c',
        '#2e70a0',
        '#22bea2',
      ],
      videoStatus: true,
      myAnimation: null,
      timer: null,
      isMob: false
    }
  },
  watch: {
    '$store.state.isMob'(newVal, oldVal) {

      // console.log(newVal, oldVal); 
      this.reload();
      this.isMob = newVal
    }
  },
  methods: {
    init() {
      if (this.isMob) {
        var imagesRatio = 1164 / 750
      } else {
        var imagesRatio = 720 / 1920
      }
      console.log(this.isMob);
      var that = this
      this.active = 0
      this.timer = null
      var index1 = 0
      var index2 = 1
      that.myAnimation = new hoverEffect({
        parent: document.querySelector('.pro_canvas'),
        intensity: -0.8,
        imagesRatio,
        image1: that.list[index1],
        image2: that.list[index1],
        displacementImage: require('@/assets/img/msk.jpg'),
      })
      this.timer = setInterval(() => {
        var index1 = that.active
        var index2 = that.active + 1
        if (index2 >= that.list.length) {
          index2 = 0
        }
        that.active = index2
        that.setProImg(index1, index2)
      }, 4000)

      // this.initWow()

      var that = this
      //  $('.btn_left').hide()
      //     $('.btn_right').hide()
      // $('.technology.banner').mousemove(function (params) {
      //   if(that.point){
      //     return
      //   }
      //   that.point = true
      //   $('.btn_left').fadeIn()
      //   $('.btn_right').fadeIn()
      //   clearInterval(pointTimer)
      //   var pointTimer = setInterval(() => {
      //     that.point = false
      //     $('.btn_left').fadeOut()
      //     $('.btn_right').fadeOut()
      //      clearInterval(pointTimer)
      //   }, 1000)
      // })
    },
    swiper(type) {
      var index1 = 0
      var index2 = 0
      if (type == 'left') {
        index1 = this.active
        index2 = this.active - 1
        if (index2 <= 0) {
          index2 = this.list.length - 1
        }
      }
      if (type == 'right') {
        index1 = this.active
        index2 = this.active + 1
        if (index2 >= this.list.length) {
          index2 = 0
        }
      }
      this.active = index2
      clearInterval(this.timer)
      this.timer = null
      this.setProImg(index1, index2)
      this.timer = setInterval(() => {
        var index1 = this.active
        var index2 = this.active + 1
        if (index2 >= this.list.length) {
          index2 = 0
        }
        this.active = index2
        this.setProImg(index1, index2)
      }, 4000)
    },
    toSpan(index) {
      clearInterval(this.timer)
      this.timer = null
      var that = this
      var index1 = that.active
      this.active = index
      var index2 = index
      if (index2 >= that.list.length) {
        index2 = 0
      }
      that.setProImg(index1, index2)
      this.timer = setInterval(() => {
        var index1 = that.active
        var index2 = that.active + 1
        if (index2 >= that.list.length) {
          index2 = 0
        }
        that.active = index2
        that.setProImg(index1, index2)
      }, 4000)
    },
    setProImg(pre, next) {
      var that = this
      $('canvas').animate(
        {
          opacity: 0,
        },
        300,
      )
      if (this.isMob) {
        var imagesRatio = 1164 / 750
      } else {
        var imagesRatio = 720 / 1920
      }
      var myAnimation = new hoverEffect({
        parent: document.querySelector('.pro_canvas'),
        intensity: -0.8,
        imagesRatio,
        image1: that.list[pre],
        image2: that.list[next],
        displacementImage: require('@/assets/img/msk.jpg'),
      })
      setTimeout(() => {
        myAnimation.next()
        setTimeout(() => {
          $('.pro_canvas').children().first().remove()
        }, 6000)
      }, 150)
    },
    setWow() {
      //   $('.service_content_2').css('transform', 'translate(-20000px, 0)');
      //   $('.aboutpark .more').css('transform', 'translate(-20000px, 0)');
      //   $('.ticket_p').css('transform', 'translate(-20000px, 0)');
      //   $('.ticket').css('transform', 'translate(-20000px, 0)');
      //   $('.routes_p').css('transform', 'translate(-20000px, 0)');
      //   $('.project_main').css('transform', 'translate(-20000px, 0)');
    },
    handleResize() {
      return () => {
        if (this.isMob) {
          return
        }
        var zoom = window.innerWidth / 1920 + 0.1
        if (window.innerWidth >= 1450) {
          $('.banner_center').css({
            'transform': 'translate(-50%, -50%) scale(1)'
          })
          return
        }
        $('.banner_center').css({
          'transform': 'translate(-50%, -50%) scale(' + zoom + ')'
        })
      }

    }
  },
  mounted() {
    this.isMob = this.$store.state.isMob

    if (this.isMob) {
      this.list = [
        require('@/assets/img/m_c1_1.png'),
        require('@/assets/img/m_c1_2.png'),
        require('@/assets/img/m_c1_3.png'),
        require('@/assets/img/m_c1_4.png'),
        require('@/assets/img/m_c1_5.png'),
        require('@/assets/img/m_c1_6.png'),
        require('@/assets/img/m_c1_7.png'),
        require('@/assets/img/m_c1_8.png'),
      ]
    } else {
      this.list = [
        require('@/assets/img/c1_1.png'),
        require('@/assets/img/c1_2.png'),
        require('@/assets/img/c1_3.png'),
        require('@/assets/img/c1_4.png'),
        require('@/assets/img/c1_5.png'),
        require('@/assets/img/c1_6.png'),
        require('@/assets/img/c1_7.png'),
        require('@/assets/img/c1_8.png'),
      ]
    }
    this.$nextTick(() => {
      this.init()
      if (this.isMob) {
        this.myAnimation = new hoverEffect({
          parent: document.querySelector('.pro_canvas'),
          intensity: -0.8,
          imagesRatio: 1164 / 750,
          image1: this.list[0],
          image2: this.list[0],
          displacementImage: require('@/assets/img/msk.jpg'),
        })
      } else {
        this.myAnimation = new hoverEffect({
          parent: document.querySelector('.pro_canvas'),
          intensity: -0.8,
          imagesRatio: 720 / 1920,
          image1: this.list[0],
          image2: this.list[0],
          displacementImage: require('@/assets/img/msk.jpg'),
        })
      }
      // this.toSpan(0)
      // if (!this.isMob) {
      if (this.isMob) {
        return
      }
      var zoom = window.innerWidth / 1920 + 0.1
      if (window.innerWidth >= 1450) {
        $('.banner_center').css({
          'transform': 'translate(-50%, -50%) scale(1)'
        })
        return
      }
      $('.banner_center').css({
        'transform': 'translate(-50%, -50%) scale(' + zoom + ')'
      })
      window.addEventListener('resize', this.handleResize())
      // }
    }, 0)

  },
  beforeDestroy() {
    clearInterval(this.timer)
    window.removeEventListener("resize", this.handleResize());
    $('.pro_canvas').children().remove()
  },
  components: {
    myMenu,
  },
}
</script>

<style lang="scss">
.technology {
  position: relative;
  height: calc(100vh - 60rem);
  overflow: hidden;
  margin-top: 60rem;
  transition: all 0.2s;
}

.technology img {
  position: absolute;
  top: 0;
  height: 720rem;
}
.banner_center {
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  transform-origin: center;
}
.pro_canvas {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
canvas {
  position: absolute;
  top: 0;
}
.index_list {
  // background: #000000;
  height: 114rem;
  display: flex;
  justify-content: center;
  // align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.index_list span {
  margin: 0 10rem;
  background: #fff;
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  cursor: pointer;
}
.index_list .active {
  background: #5f21b4;
}
.text_box {
  width: 1200rem;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
.box_item {
  width: 684px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  h1 {
    color: #fff;

    font-family: 'Microsoft YaHei';
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 156.25% */
  }
  p {
    color: #fff;
    font-family: 'Microsoft YaHei';
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
  }
}
.animate__delay3 {
  animation-delay: 0.5s;
}
.animate__delay6 {
  animation-delay: 0.8s;
}
.box1 {
  right: -118rem;
}
.box2 {
  left: -58rem;
}
.box3 {
  left: -58rem;
}
.box4 {
  right: -58rem;
}
.box5 {
  left: -58rem;
}
.box6 {
  right: -68rem;
}
.box7 {
  left: -46rem;
}
.box8 {
  left: -58rem;
}

.btn_left {
  width: 68rem;
  height: 68rem;
  cursor: pointer;
  background-image: url('@/assets/img/icon_left_ed.png');
  background-image: url('@/assets/img/icon_left.png');
  background-size: cover;
  position: absolute;
  top: calc(50% - 34rem);
  left: 44rem;
  z-index: 1;
  // &:hover {
  //   background-image: url('@/assets/img/icon_left_ed.png');
  // }
}
.btn_right {
  width: 68rem;
  height: 68rem;
  cursor: pointer;
  background-image: url('@/assets/img/icon_right_ed.png');
  background-image: url('@/assets/img/icon_right.png');
  background-size: cover;
  position: absolute;
  top: calc(50% - 34rem);
  right: 44rem;
  z-index: 1;
  // &:hover {
  //   background-image: url('@/assets/img/icon_right_ed.png');
  // }
}
@media screen and (max-width: 767px) {
  .technology {
    height: calc(100vh - 100rem);
    margin-top: 100rem;
  }
  .box_item {
    width: 662rem;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 100rem;
    transform: translateY(0);
    h1 {
      font-size: 40rem;
      text-align: center;
    }
    p {
      font-size: 28rem;
      line-height: 50rem;
    }
  }
  .box8 p{
    text-align:center;
  }
}
</style>

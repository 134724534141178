
<template>
  <div>
    <div class="platform banner">
      
      <video id="myVideoD" class="video-js vjs-default-skin vjs-fluid" muted></video>
      <img v-if="posterShow" class="poster" :src="poster" alt="">
      <!-- ffmpeg -i input.mp4 -c copy -f segment -segment_list playlist.m3u8 -segment_time 2 output_folder%03d.ts -->
      <!-- ffmpeg -i b.mp4 -hls_time 10 -hls_list_size 0 -hls_segment_filename b.ts b.m3u8 -->
      <div v-if="tip" class="tip" @click="toCall">
        <span>预约体验</span>
        <i></i>
      </div>
      <div v-if="isMob&&!isIos&&isbtn&&$store.state.isWx&&!isClick" class='videoBtn'>观看视频</div>
    </div>
  </div>
</template>

<script> 
import videojs from "video.js";

import "video.js/dist/video-js.css";
function options(src, type) {
  return {
    autoplay: true,
    muted: true,
    loop: true,
    controls: false,
    preload: "auto",
    fluid: true,
    sources: [
      {
        type,
        src,
      },
    ],
    notSupportedMessage: "视频资源无法加载",
    textTrackDisplay: false,
    
  };
}
export default {
   inject: ['reload'],
  components: { 
  },
  data() {
    return {
      tip: false,
      attachmentLink: "/cut/b1/b1.m3u8",
      srctype: "application/vnd.apple.mpegurl",
      srctype1: "video/mp4",
      player: null,
      statusPlay: false,
      posterShow: true,
       isMob: false,
       poster:require('@/assets/img/b1poster.jpg'),
       isbtn:true,
    }
  },
   computed: {
    isClick() {
      return this.$store.state.isClick
    },
    isIos(){
      return /iPad|iPhone|iPod/.test(navigator.userAgent)
    }
  },
  watch: {
    '$store.state.isMob'(newVal, oldVal) {
      this.isMob = newVal  
        this.reload();
        this.isMob = newVal  
    },
    '$store.state.isClick'(newVal, oldVal) { 
      this.player.play() 
      this.isbtn = false
    },
  },
  mounted() {
    this.isMob = this.$store.state.isMob
    var that = this
    this.$nextTick(() => {
       $('video').attr('playsinline','true').attr('webkit-playsinline','true')
      this.getVideo();
    });
    setTimeout(function (params) {
      // $('.top').show() 
      that.tip = true
    }, 5000)
  },
  beforeDestroy() {
    this.update()
  },
  methods: {
    update() {
      this.player.dispose()
    },
    getVideo() {
      var that = this
      this.attachmentLink = this.isMob?'/cut/b1_m/b1_m.m3u8':'/cut/b1/b1.m3u8' 
      this.poster=this.isMob?require('@/assets/img/b1mposter.jpg'):require('@/assets/img/b1poster.jpg')
      this.player = videojs('myVideoD', options(this.attachmentLink, this.srctype), () => {
        //player.play();
        that.player.on("loadstart", function () {
         $('video').attr('playsinline','true').attr('webkit-playsinline','true')
        });
        that.player.on("play", function () {
          console.log("Play video");
        });
        that.player.on("pause", function () {
          console.log("Pause video");
          that.player.play()
        });
        that.player.on("ended", function () {
          console.log("Video end");
        });
        that.player.on('playing', function () {
            //渲染播放画面
            // console.log('playing') 
            that.posterShow = false
            that.isbtn = false
          })
        that.player.on('loadeddata', function () {
          console.log('loadeddata---渲染播放画面----');//autoPlay必须为false
          // that.posterShow = false
          // that.isbtn = false
        });
      });
      // $('body').click(function (params) {
      //   if (that.statusPlay) {
      //     return
      //   }
      //   that.statusPlay = true
      //   that.player.muted(false)
      // })
    },
    toCall() {
      window.scrollTo(0, 1000);

    }
  },
}
</script>
<style>
html {
  min-height: 100vh;
  background-color: #0c0c0c;
}
.poster {
  width: auto !important;
  height: 720rem !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 1920px) {
  .poster {
    height: auto !important;
    width: 100% !important;
  }
}
.type2_con .banner {
  background: #000 !important;
}
</style>
<style lang="scss" scoped>
/* 平台 */
.platform {
  height: 700rem;
  width: 100%;
  background: #000;
  margin-top: 60rem;
  position: relative;
  overflow: hidden;
  .video-js {
    width: auto !important;
    height: 720rem !important;
    padding: 0 !important;
    pointer-events: none;
     left: 50%; 
     top: 50%; 
    position: relative;
     transform: translate(-50%, -50%); 
  }
  .video-js .vjs-tech {
    width: auto !important;
    height: 100% !important;
  }
  @media screen and (min-width: 1920px) {
    .video-js {
      width: 100% !important;
      height: auto !important;
    }
    .video-js .vjs-tech {
      height: auto !important;
      width: 100% !important;
    }
  }
}
.banner video {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
@keyframes fadeInAndBounce {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  70% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    transform: translateY(-10px);
  }
  90% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.tip {
  width: 180rem;
  height: 50rem;
  line-height: 50rem;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  border: 1rem solid rgba(95, 33, 180, 1);
  font-size: 18rem;
  color: rgba(95, 33, 180, 1);
  border-radius: 50rem;
  text-align: center;
  backdrop-filter: blur(10rem);
  letter-spacing: 6rem;
  cursor: pointer;
  overflow: hidden;
  animation: fadeInAndBounce 2s ease-in-out 1;
  span {
    height: 100%;
    display: block;
    width: 100%;
    transition: all 0.3s;
  }
  &:hover span {
    margin-top: -50rem;
  }
  i {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: url('@/assets/img/icon_down.png');
    background-size: 20px 24px;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 767px) {
  .videoBtn{ 
  border:1rem solid #fff;
        color: #fff;
        border-radius: 99px;
        font-size: 32rem;
        width: 277rem;
        height: 70rem;
        line-height: 70rem;
        text-align: center;
        
            position: absolute;
        backdrop-filter: blur(15px);
        top: calc(50% + 138rem);
        left: 50%;
        transform: translate(-50%,-50%);
}
  .banner {
    height: calc(100vh - 100rem) !important;
    margin-top:100rem;
    p {
      font-size: 70rem;
    }
  }
  .banner .video-js {
    height: calc(100vh - 100rem) !important;
  }
  .poster {
    height: calc(100vh - 100rem) !important;
  }
  .tip {
    width: 355rem;
    height: 98rem;
    font-size: 35rem;
    span {
      line-height: 98rem;
      margin: 0 !important;
    }
    i {
      display: none;
    }
  }
  .banner .video-js .vjs-tech{
    width: 100% !important;
    height:auto !important;
    // height: max-content !important;
    // width: max-content !important;
  }
  .banner video {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
}
@media screen and (max-width: 514px) {
 .banner .video-js .vjs-tech{
    // width: 100% !important;
    // height: max-content !important;
    height: 100% !important;
    width:auto !important;
  }
}
</style>
<template>
  <div>
    <canvas id="videoCanvas" width="640" height="480"></canvas> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFrame: 0,
      totalFrames: 0,
      frames: [],
      playing: true,
      canvas: null,
      ctx: null,
      frameInterval: 1000 / 60, // 1000ms / 60fps = 16.666ms per frame
    };
  },
  mounted() {
    this.canvas = document.getElementById('videoCanvas');
    this.ctx = this.canvas.getContext('2d');

    // Load the first frame to start with
    this.loadNextFrame();
  },
  methods: {
    async loadNextFrame() {
      if (this.currentFrame >= this.totalFrames) {
        // If we've reached the end of the video, reset and start over.
        this.currentFrame = 0;
      }

      const img = new Image();
      img.src = await this.loadFrame(this.currentFrame);
      img.onload = () => {
        this.renderFrame(img);
        if (this.playing) {
          requestAnimationFrame(() => this.loadNextFrame());
        }
      };
    },
    async loadFrame(frameNumber) {
      const framePath = `a1_m2/a1_${String(frameNumber+1).padStart(6, '0')}.png`;
      return await import(`@/assets/${framePath}`).then((module) => module.default);
    },
    renderFrame(image) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
      this.currentFrame++;
    }, 
  },
};
</script>
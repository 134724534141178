<template>
  <div>
    <div class="banner">
      <!-- <imgcom2></imgcom2> -->
      <video id="myVideo" class="video-js vjs-default-skin vjs-fluid" muted></video>
      <img v-if="posterShow" class="poster" :src="poster" alt="" />
      <!-- <video autoplay loop muted src="@/assets/img/a1.mp4"></video> -->
      <!-- <img src="@/assets/img/a1.png" alt="" srcset=""> -->
      <div class="banner_con">
        <p class="wow fadeInUp" data-wow-delay="0.3s"><span>AI</span>与人共创虚拟世界</p>
        <div v-if="isMob&&isbtn&&$store.state.isWx&&!isIos&&!isClick" class='videoBtn'>观看视频</div>
        <!-- <div class="btn_type1 wow fadeInUp" data-wow-delay="0.5s" data-ripplet="color: rgba( 64, 192, 255, .2); spreading-duration: 2s; clearing-delay: 1.8s;" onmouseover="ripplet(event)">立即体验</div> -->
        <!-- <div @click="toPage('platform')" class="btn_type1 wow fadeInUp" data-wow-delay="0.6s">立即体验</div> -->
      </div>
    </div>
    <div class="block1" v-if="!isMob">
      <div class="block1_con">
        <h1 class="block_title wow fadeInUp" data-wow-delay="0.3s">
          GAG（General Action Generation Techstack）
          <br />
          通用型运动生成技术
        </h1>
        <div class="box">
          <div class="box_list">
            <div class="list_item active wow fadeInUp" data-wow-delay="0.3s">
              <span>Text to Motion</span>
              <p>基于文本描述生成3D人体动作</p>
              <span @click.stop="toPage('platform')" class="list_go">
                立即体验
              </span>
            </div>
            <div class="list_item wow fadeInUp" data-wow-delay="0.5s">
              <span>Generative Motion Matching</span>
              <p>实时匹配和追踪关键运动轨迹，生成高质量动作</p>
              <span @click.stop="toPage('platform')" class="list_go">
                立即体验
              </span>
            </div>
            <div class="list_item wow fadeInUp" data-wow-delay="0.7s">
              <span>Images to Motion</span>
              <p>静态照片角色转化为3D动作数据</p>
              <span @click.stop="toPage('platform')" class="list_go">
                立即体验
              </span>
            </div>
          </div>
          <div class="box_con wow fadeInUp" data-wow-delay="0.3s">
            <video class="videoBlock" id="myVideoA" loop muted></video>
            <video class="videoBlock" id="myVideoB" loop muted></video>
            <video class="videoBlock" id="myVideoC" loop muted></video>
          </div>
        </div>
      </div>
    </div>
    <div class="block1 block2" v-if="isMob">
      <div class="block1_con">
        <h1 class="block_title wow fadeInUp" data-wow-delay="0.3s">
          GAG（General Action Generation Techstack）
          <br />
          <span>通用型运动生成技术</span>
        </h1>
        <div class="box">
          <div class="box_con wow fadeInUp" data-wow-delay="0.3s">
            <video class="videoBlock" id="myVideoA" loop muted></video>
          </div>
          <div class="box_list">
            <div class="list_item active wow fadeInUp" data-wow-delay="0.3s">
              <span>Text to Motion</span>
              <p>基于文本描述生成3D人体动作</p>
              <span @click.stop="toPage('platform')" class="list_go">
                立即体验
              </span>
            </div>
          </div>

        </div>
        <div class="box">
          <div class="box_con wow fadeInUp" data-wow-delay="0.3s">
            <video class="videoBlock" id="myVideoB" loop muted></video>
          </div>
          <div class="box_list">
            <div class="list_item active wow fadeInUp" data-wow-delay="0.5s">
              <span>Generative Motion Matching</span>
              <p>实时匹配和追踪关键运动轨迹，生成高质量动作</p>
              <span @click.stop="toPage('platform')" class="list_go">
                立即体验
              </span>
            </div>
          </div>

        </div>
        <div class="box">
          <div class="box_con wow fadeInUp" data-wow-delay="0.3s">
            <video class="videoBlock" id="myVideoC" loop muted></video>
          </div>
          <div class="box_list">
            <div class="list_item active wow fadeInUp" data-wow-delay="0.7s">
              <span>Images to Motion</span>
              <p>静态照片角色转化为3D动作数据</p>
              <span @click.stop="toPage('platform')" class="list_go">
                立即体验
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import 'animate.css'

import videojs from 'video.js'

import 'video.js/dist/video-js.css'
import imgcom2 from '@/components/imgcom2.vue';
function options(src, type) {
  return {
    autoplay: true,
    muted: true,
    loop: true,
    controls: false,
    preload: 'auto',
    fluid: true,
    sources: [
      {
        type,
        src,
      },
    ],
    notSupportedMessage: '视频资源无法加载',
    textTrackDisplay: false,
  }
}
function options2(src, type, poster) {
  return {
    autoplay: true,
    muted: true,
    loop: true,
    controls: true,
    preload: 'auto',
    fluid: true,

    sources: [
      {
        type,
        src,
      },
    ],
    poster,
    notSupportedMessage: '视频资源无法加载',
    textTrackDisplay: false,
  }
}
export default {
  inject: ['reload'],
  components: {imgcom2},
  data() {
    return {
      attachmentLink: '/cut/a1/a1.m3u8',
      srctype: 'application/vnd.apple.mpegurl',
      srctype1: 'video/mp4',
      player: null,
      statusPlay: false,
      play2: false, //滚动条控制视频播放
      playerA: null,
      playerB: null,
      playerC: null,
      posterShow: true,
      statusPage: 0,
      isMob: false,
      poster:require('@/assets/img/a1poster.jpg'),
      isbtn:true,
    }
  },
  computed: {
    isClick() {
      return this.$store.state.isClick
    },
    isIos(){
      return /iPad|iPhone|iPod/.test(navigator.userAgent)
    }
  },
  watch: {
    '$store.state.isMob'(newVal, oldVal) {
      this.isMob = newVal
      // console.log(newVal, oldVal);
      this.$forceUpdate()
      if (this.statusPage > 0) {
        // console.log('页面刷新');
        this.reload();
        this.isMob = newVal
        // window.location.reload()
      }
    },
     '$store.state.isClick'(newVal, oldVal) { 
       this.$nextTick(() => {
        this.play2 = true 
         this.player.play()
         this.playerA.play()
         this.playerB.play()
         this.playerC.play()
         this.isbtn = false
       })
    },
  },
  beforeDestroy() {
    this.update()
  },
  methods: {
    update() {
      this.player.dispose()
      this.playerA.dispose()
      this.playerB.dispose()
      this.playerC.dispose()
      window.removeEventListener('scroll', this.handleScroll)
    },
    handleScroll(e) {
      if(this.$store.state.isMob){
        this.play2 = true
        return
      }
      if (this.play2) {
        return
      }
      var t = document.documentElement.scrollTop || document.body.scrollTop //变量t就是滚动条滚动
      if (t >= 530) {
        this.play2 = true
        this.playerA.play()
        // $('.block1 video').eq(0)[0].play()
      }
    },
    getVideo() {
      var that = this
      this.attachmentLink = this.isMob?'/cut/a1_m/a1_m.m3u8':'/cut/a1/a1.m3u8' 
      this.poster=this.isMob?require('@/assets/img/a1mposter.jpg'):require('@/assets/img/a1poster.jpg')
      this.player = videojs(
        'myVideo',
        options(this.attachmentLink, this.srctype),
        () => {
          //player.play();
          that.player.on('loadstart', function () {
            // console.log('Start load video')
            that.statusPage++
            $('video').attr('playsinline','true').attr('webkit-playsinline','true')
          })
          that.player.on('play', function () {
            // console.log('Play video')
          })
          that.player.on('pause', function () {
            // console.log('Pause video')
            that.player.play()
          })
          that.player.on('ended', function () {
            // console.log('Video end')
          })
          that.player.on('playing', function () {
            //渲染播放画面
            // console.log('playing') 
            that.posterShow = false
            that.isbtn = false
          })
          that.player.on('loadeddata', function () { 
            // that.posterShow = false
            // that.isbtn = false
          })
        },
      )
      this.playerA = videojs(
        'myVideoA',
        options('/cut/a2_1/a2_1.m3u8', this.srctype),
        () => {
          that.playerA.on('loadstart', function () {
            $('video').attr('playsinline','true').attr('webkit-playsinline','true')
            that.statusPage++
          })
          that.playerA.on('play', function () {
            // console.log('play video', that.play2)
            that.isbtn = false
            if (!that.play2) {
              that.playerA.pause()
            }
          })
          that.playerA.on('pause', function () {
            // console.log('Pause video', that.play2)
            if (that.play2) {
              that.playerA.play()
            } else {
              that.playerA.pause()
            }
          })
          that.playerA.on('ended', function () {
            // console.log('Video end')
          })
        },
      )
      this.playerB = videojs(
        'myVideoB',
        options('/cut/a2_2/a2_2.m3u8', this.srctype),
        () => {
          //player.play();
          that.playerB.on('loadstart', function () {
            // console.log('Start load video')
            $('video').attr('playsinline','true').attr('webkit-playsinline','true')
            that.statusPage++
          })
          that.playerB.on('play', function () {
            // console.log('Play video')
            that.isbtn = false
          })
          that.playerB.on('pause', function () {
            // console.log('Pause video')
            that.playerB.play()
          })
          that.playerB.on('ended', function () {
            // console.log('Video end')
          })
        },
      )
      this.playerC = videojs(
        'myVideoC',
        options('/cut/a2_3/a2_3.m3u8', this.srctype),
        () => {
          //player.play();
          that.playerC.on('loadstart', function () {
            // console.log('Start load video')
            $('video').attr('playsinline','true').attr('webkit-playsinline','true')
            that.statusPage++
          })
          that.playerC.on('play', function () {
            // console.log('Play video')
            that.isbtn = false
          })
          that.playerC.on('pause', function () {
            // console.log('Pause video')
            that.playerC.play()
          })
          that.playerC.on('ended', function () {
            // console.log('Video end')
          })
        },
      )
    },
    toPage(url) {
      this.$router.push({ path: 'platform' })
    },
  },
  mounted() {
    this.isMob = this.$store.state.isMob
    this.$nextTick(() => { 
      $('video').attr('playsinline','true').attr('webkit-playsinline','true')
      this.getVideo()
      
    })
    window.addEventListener('scroll', this.handleScroll)
    var wow = new WOW({
      animateClass: 'animated',
      offset: 100,
      live: false,
    })
    wow.init()
    var that = this
    $('.list_item').click(function (params) {
      if(that.playerA&&that.playerB&&that.playerC)
      this.play2 = true
      var pre = $('.list_item.active').index()
      var index = $(this).index()
      $('.list_item').removeClass('active')
      $(this).addClass('active')
      $('.block1 .videoBlock')
        .eq(pre)
        .animate(
          {
            opacity: 0,
          },
          500,
          () => {
            $('.block1 .videoBlock').eq(pre).hide()
            if (pre == 0) {
              that.playerA.currentTime(0)
            } else if (pre == 1) {
              that.playerB.currentTime(0)
            } else {
              that.playerC.currentTime(0)
            }
          },
        )
      if (index == 0) {
        that.playerA.currentTime(0)
      } else if (index == 1) {
        that.playerB.currentTime(0)
      } else {
        that.playerC.currentTime(0)
      }
      $('.block1 .videoBlock').eq(index).show()
      $('.block1 .videoBlock').eq(index).css({
        opacity: 1,
        left: '860px',
      })
      $('.block1 .videoBlock')
        .eq(index)
        .animate(
          {
            left: '0',
          },
          500,
          () => {
            if (index == 0) {
              that.playerA.currentTime(0)
              that.playerA.play()
            } else if (index == 1) {
              that.playerB.currentTime(0)
              that.playerB.play()
            } else {
              that.playerC.currentTime(0)
              that.playerC.play()
            }
            $('.block1 .videoBlock').eq(pre).hide()
          },
        )
    })
  },
}
</script>
<style>
.poster {
  width: auto !important;
  height: 720rem !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 1920px) {
  .poster {
    height: auto !important;
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
.banner {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #000000;
  height: 720rem !important;
  .video-js {
    width: auto !important;
    height: 720rem !important;
    padding: 0 !important;
    pointer-events: none;
    left: 50%; 
     top: 50%; 
    position: relative;
     transform: translate(-50%, -50%); 
  }
  .video-js .vjs-tech {
    width: auto !important;
    height: 100% !important;
  }

  @media screen and (min-width: 1920px) {
    .video-js {
      width: 100% !important;
      height: auto !important;
    }
    .video-js .vjs-tech {
      height: auto !important;
      width: 100% !important;
    }
    .video-js .vjs-poster {
      height: auto !important;
      width: 100% !important;
    }
  }
}

.banner video {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.banner .banner_con {
  position: absolute;
  width: 1000rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner p {
  color: #fff;
  text-align: center;
  text-shadow: 0rem 4rem 6rem rgba(0, 0, 0, 0.25);
  font-family: 'Microsoft YaHei';
  font-size: 76rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.banner p span{
  font-family: 'HarmonyOS';
}
.banner .btn_type1 {
  display: inline-block;
  height: 56rem;
  line-height: 56rem;
  padding: 0 52rem;
  text-align: center;
  border-radius: 51px;
  background: #5f21b4;
  color: #fff;
  font-family: 'Microsoft YaHei';
  font-size: 24rem;
  font-weight: 700;
  margin-top: 30rem;
  cursor: pointer;
}

.block1 {
  width: 100%;
  height: 813rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.block1 .block_title {
  color: #fff;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30rem;
}

.block1 .box_list {
  display: flex;
  flex-direction: column;
}

.block1 .box_list .list_item {
  width: 366rem;
  height: 150rem;
  background-color: #191919;
  box-sizing: border-box;
  margin-bottom: 17rem;
  box-sizing: border-box;
  padding: 21rem 21rem;
  position: relative;
  cursor: pointer;
}

.block1 .box_list .list_item.active {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.block1 .box_list .list_item span {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20rem;
}

.block1 .box_list .list_item p {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-top: 10rem;
}

.block1 .box_list .list_item .list_go {
  color: #8035e7;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  right: 35rem;
  bottom: -5rem;
  opacity: 0;
  transition: all 0.3s;
}

.block1 .box_list .list_item.active .list_go {
  opacity: 1;
}

.block1 .box {
  display: flex;
}

.block1 .box .box_con {
  width: 860px;
  height: 484px;
  margin-left: 10rem;
  overflow: hidden;
  position: relative;
}

.block1 .box .box_con .videoBlock {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 !important;
}
.block1 .box .box_con .videoBlock:nth-child(1) {
  display: block;
}
@media screen and (max-width: 1300px) {
  .box {
    max-width: calc(100vw - 52rem);
  }
  .block1 .box_list {
    flex: 1;
    .list_item {
      width: 100%;
      span {
        font-size: 16px;
      }
      p {
        font-size: 14rem;
      }
    }
  }
}
@media screen and (max-width: 767px) {
.videoBtn{ 
  border:1rem solid #fff;
        color: #fff;
        border-radius: 99px;
        font-size: 32rem;
        width: 277rem;
        height: 70rem;
        line-height: 70rem;
        text-align: center;
        bottom: -128rem;
            position: absolute;
        backdrop-filter: blur(15px);
}
  .banner {
    height: 100vh !important;
    p {
      font-size: 70rem;
    }
  }
  .banner .video-js {
    height: 100vh !important;
  }
  .poster {
    height: 100vh !important;
  }
  .block2 {
    height: auto;
  }
  .block2 .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: initial;
  }
  .block2 .box .box_con {
    width: 100%;
    height: 422.093rem;
  }
  .block2 .box .box_con video {
    width: 100%;
    height: auto;
  }
  .block2 .box .box_con .videoBlock {
    position: relative;
    height: auto;
  }
  .block2 .box_list .list_item {
    width: 716rem;
    height: 294rem;
    margin: 0 auto;
    margin-top: 17rem;
    margin-bottom: 58rem;
    padding: 47rem 21rem;
  }
  .block2 .box .box_con {
    margin: 0 auto;
  }
  .block2 .box_list .list_item span {
    font-size: 42rem;
    display: block;
    text-align: center;
  }
  .block2 .box_list .list_item p {
    font-size: 30rem;
    display: block;
    text-align: center;
  }
  .block2 .box_list .list_item .list_go {
    right: 0;
    left: 0;
    bottom: 26rem;
    font-size: 28rem;
  }
  .block1 .block_title {
    font-size: 42rem;
    margin: 84rem 0 84rem;
  }
  .block1 .block_title span {
    font-size: 34rem;
  }
.banner .video-js .vjs-tech{
    width: 100% !important;
    height:auto !important;
    // height: max-content !important;
    // width: max-content !important;
  }
  .banner video {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
}
@media screen and (max-width: 514px) {
 .banner .video-js .vjs-tech{
    // width: 100% !important;
    // height: max-content !important;
    height: 100% !important;
    width:auto !important;
  }
}
</style>

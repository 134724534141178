import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import platform from '@/views/platform.vue'
import technology from '@/views/technology.vue'
import aboutUs from '@/views/aboutUs.vue'
import recruit from '@/views/recruit.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: index,
  meta: {
    title: '',
  }
},
{
  path: '/index',
  component: index,
  meta: {
    title: '',
  }
},
{
  path: '/platform',
  component: platform,
  meta: {
    title: '',
  }
},
{
  path: '/technology',
  component: technology,
  meta: {
    title: '',
  }
}
  , {
  path: '/aboutUs',
  component: aboutUs,
  meta: {
    title: '',
  }
}, {
  path: '/recruit',
  component: recruit,
  meta: {
    title: '',
  }
}
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 如果之前有保存滚动位置，则恢复到之前保存的位置
      return savedPosition;
    } else {
      // 否则，默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  }
})

export default router

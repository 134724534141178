<template>
  <div class="top_menu" :class="{ top_menu2: type == 2 }">
    <div class="top_con">
      <div class="logo" @click="toPage('index')"></div>
      <div class="icon_menu" @click="open" :class="{ active: isActive }"></div>
      <ul>
        <li :class="{ active: active == '/index' || active == '/' }">
          <a @click="toPage('index')">首页</a>
        </li>
        <li :class="{ active: active == '/platform' }">
          <a @click="toPage('platform')">平台</a>
        </li>
        <li :class="{ active: active == '/technology' }">
          <a @click="toPage('technology')">技术</a>
        </li>
        <li :class="{ active: active == '/aboutUs' }">
          <a @click="toPage('aboutUs')">关于我们</a>
        </li>
        <li :class="{ active: active == '/recruit' }">
          <a @click="toPage('recruit')">人才招聘</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      active: '',
      isActive: false,
      type: 1,
    }
  },
  watch: {
    '$route'(to, from) {
      this.active = to.path


      if (this.active == '/platform' || this.active == '/technology') {
        this.type = 2
      } else {
        this.type = 1
      }
    },
    '$store.state.isMob'(newVal, oldVal) {
      this.isActive = false
      if (this.$store.state.isMob) {
        $('.top_menu ul').slideUp(0)
      } else {
        $('.top_menu ul').show()
      }
    }
  },
  computed: {
    isMob() {
      if (this.$store.state.isMob) {

        $('.top_menu ul').slideDown(0)
      }
      return this.$store.state.isMob
    }
  },
  methods: {
    toPage(url) {
      this.isActive = !this.isActive
      if (this.$route.fullPath == '/' + url) {
        $('.top_menu ul').slideToggle()
        return
      }
      if (this.$route.fullPath == '/' && url == 'index') {
        $('.top_menu ul').slideToggle()
        return
      }
      if (this.isMob) {
        $('.top_menu ul').slideToggle()
      }

      this.$router.push({ path: url })
    },
    open() {
      this.isActive = !this.isActive
      $('.top_menu ul').slideToggle()
    },
  },
  created() { },
  mounted() {
    this.active = this.$route.path
    if (this.active == '/platform' || this.active == '/technology') {
      this.type = 2
    }
    if (this.$store.state.isMob) {
      $('.top_menu ul').slideDown(0)
    }
  },
}
</script>
<style lang="scss" scoped>
.top_menu ul li:hover {
  &::after {
    width: calc(100% + 30rem);
  }
}
.top_menu2 {
  background: #000 !important;
}
</style>

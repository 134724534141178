import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMob: false,
    isClick: false,
    isWx: false,
  },
  getters: {
    isMob: state => state.isMob,
    isClick: state => state.isClick,
    isWx: state => state.isClick,
  },
  mutations: {
    setIsMob(state, data) {
      state.isMob = data;
    },
    setIsClick(state, data) {
      state.isClick = data;
    },
    setIsWx(state, data) {
      state.isWx = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
